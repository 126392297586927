<template>
  <!-- 编码:lzq -->
  <div>
    <main>
      <!-- 头部横幅 -->
      <div class="banner">
        <div class="banner1">
          <img v-lazy="require('../assets/imgs/journey/banner.webp')" alt="" />
        </div>
        <!-- 横幅内容区域 -->
        <div class="banner2">
          <h2 class="title">行程规划</h2>
          <p class="introduce">
            旅游行程进行管理，在旅游前进行行程规划，在旅游时进行行程记录，在旅游后方便的生成旅行游记
          </p>
        </div>
      </div>
      <p class="title_h2">方案优势</p>
      <!-- 方案优势区域 -->
      <div class="first_box">
        <div class="box_1">
          <div>
            <img
              v-lazy="require('../assets/imgs/journey/rectangle2.webp')"
              alt=""
            />
          </div>
          <h5>优质目的地推荐</h5>
        </div>
        <div class="box_1">
          <div>
            <img
              v-lazy="require('../assets/imgs/journey/rectangle2.2.webp')"
              alt=""
            />
          </div>
          <h5>便捷交通路线</h5>
        </div>
        <div class="box_1">
          <div>
            <img
              v-lazy="require('../assets/imgs/journey/rectangle2.4.webp')"
              alt=""
            />
          </div>
          <h5>智能住宿推荐</h5>
        </div>
        <div class="box_2">
          <div>
            <img
              v-lazy="require('../assets/imgs/journey/rectangle2.1.webp')"
              alt=""
            />
          </div>
          <h5>旅游景点的细化调整</h5>
        </div>
        <div class="box_2">
          <div>
            <img
              v-lazy="require('../assets/imgs/journey/rectangle2.3.webp')"
              alt=""
            />
          </div>
          <h5>行程细化</h5>
        </div>
        <div class="box_2">
          <div>
            <img
              v-lazy="require('../assets/imgs/journey/rectangle2.5.webp')"
              alt=""
            />
          </div>
          <h5>外设准备推荐</h5>
        </div>
      </div>
      <!-- 应用场景区域 -->
      <div class="two_box">
        <p>应用场景</p>
        <div class="two_items">
          <div class="list_1">
            <div class="image">
              <img
                v-lazy="require('../assets/imgs/journey/gand5.webp')"
                alt=""
              />
            </div>
            <div class="content">
              <h4>旅行前规划</h4>
              <span>
                将旅行中准备去的景点、餐厅、购物场所都预先收藏，到达目的地以后方便查找。
              </span>
            </div>
          </div>

          <div class="list_2">
            <div class="content">
              <h4>旅行时记录</h4>
              <span> 旅行中，将去过的地方一一打卡记录，生成旅行足迹。 </span>
            </div>
            <div class="image">
              <img
                v-lazy="require('../assets/imgs/journey/gand5.2.webp')"
                alt=""
              />
            </div>
          </div>

          <div class="list_1">
            <div class="image">
              <img
                v-lazy="require('../assets/imgs/journey/gand5.1.webp')"
                alt=""
              />
            </div>
            <div class="content">
              <h4>旅行后一键生成游记</h4>
              <span>
                旅行结束后，整理行程，传图片，补充文字，一键生成游记，分享给好友。
              </span>
            </div>
          </div>
        </div>
      </div>
    </main>
    <!-- 头部组件 -->
    <Header></Header>
    <!-- 底部组件 -->
    <Footer></Footer>
  </div>
</template>
<script>
// @ is an alias to /src
//引入头部组件
import Header from "@/components/Header.vue";
// 引入底部组件
import Footer from "@/components/Footer.vue";
export default {
  name: "optimization_algorithm",
  components: {
    Header,
    Footer,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.banner {
  width: 100%;
  height: 600px;
  color: #fff;
  position: relative;
  .banner1 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    // loading大小
    img[lazy="loading"] {
      width: 200px;
    }
    // 实际图片大小
    img[lazy="loaded"] {
      width: 100%;
      height: 100%;
    }
  }
  .banner2 {
    width: 100%;
    height: 600px;
    position: absolute;
    top: 0;
    background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000000 130%);
    z-index: 1;
    .title {
      height: 67px;
      font-size: 48px;
      font-weight: 500;
      line-height: 67px;
      letter-spacing: 3px;
      text-align: left;
      position: absolute;
      left: 361px;
      top: 230px;
    }
    .introduce {
      width: 565px;
      font-size: 24px;
      font-weight: 400;
      line-height: 33px;
      text-align: left;
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
      position: absolute;
      left: 361px;
      top: 321px;
    }
  }
}
.introduce,
.title {
  animation: sport 0.5s;
  transform: translateY(0px);
}
//设置头部文字动画效果
@keyframes sport {
  0% {
    transform: translateY(80px);
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

//版心
.title_h2 {
  font-size: 40px;
  font-weight: 500px;
  color: #333;
  letter-spacing: 1px;
  margin-bottom: 60px;
  margin-top: 80px;
}
.first_box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 1200px;
  height: 712px;
  margin: auto;
  margin-bottom: 100px;
  .box_1,
  .box_2 {
    width: 380px;
    height: 341px;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    div {
      width: 100%;
      height: 260px;
      display: flex;
      justify-content: center;
      align-items: center;
      // loading大小
      img[lazy="loading"] {
        width: 100px;
      }
      // 实际图片大小
      img[lazy="loaded"] {
        width: 100%;
        height: 100%;
      }
    }
    h5 {
      font-size: 24px;
      font-weight: 500;
      color: #333333;
      margin-top: 24px;
    }
  }
  .box_2 {
    margin-top: 30px;
  }
}
.two_box {
  box-sizing: border-box;
  width: 100%;
  height: 1394px;
  background: #f6f8fb;
  padding-top: 80px;
  p {
    font-size: 40px;
    font-weight: 500;
    color: #333333;
    letter-spacing: 1px;
    margin-bottom: 60px;
  }
  .two_items {
    width: 1200px;
    height: 1131px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .list_1,
    .list_2 {
      width: 1200px;
      height: 357px;
      display: flex;
      box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      .image {
        width: 600px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        // loading大小
        img[lazy="loading"] {
          width: 150px;
        }
        // 实际图片大小
        img[lazy="loaded"] {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
.content {
  width: 600px;
  height: 100%;
  text-align: left;
  h4 {
    margin-top: 112px;
    margin-bottom: 24px;
    font-size: 32px;
    font-weight: 500;
    color: #333333;
    letter-spacing: 1px;
    margin-left: 48px;
  }
  span {
    display: inline-block;
    width: 485px;
    margin-left: 48px;
    font-size: 20px;
    font-weight: 400;
    color: #999999;
  }
}
</style>